<template>
  <q-toggle dense v-model="value.multiSelect" label="Multiple select?" />
  <q-space class="q-mt-md" />
  <q-list v-if="value.options.length" :key="componentKey">
    <q-item class="q-pa-none" v-for="(option, index) in value.options" :key="index">
      <q-item-section avatar>
        <ColorPickerDropdown v-model="option.color" />
      </q-item-section>
      <q-item-section>
        <q-input
          lazy-rules
          :rules="[val => val && val.length > 0]"
          v-model="option.label"
          dense
          class="q-field--no-validate-message bg-white"
          placeholder="Label title"
        />
      </q-item-section>
      <q-item-section side top class="q-mt-xs self-end">
        <q-btn size="xs" flat round icon="clear" @click="onRemove(option)" :disabled="value.options.length <= 1" />
      </q-item-section>
    </q-item>
  </q-list>
  <q-btn
    outline
    no-caps
    padding="0 10px"
    class="w-full q-mt-md"
    text-color="primary"
    icon="add"
    @click.prevent="onAdd"
    label="New option"
  />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { randomString } from '@/utils/helpers'
import { COLORS_LIST } from '@/constants/vars'
import { ICustomField, ICustomFieldOption } from '@/components/custom-field/custom-field-model'
import cloneDeep from 'lodash/cloneDeep'
import ColorPickerDropdown from '@/components/color/ui/ColorPickerDropdown.vue'

@Options({
  components: { ColorPickerDropdown },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class CustomFieldLabelSetting extends Vue {
  @Prop()
  modelValue!: ICustomField

  componentKey = 1

  get value(): ICustomField {
    return this.modelValue
  }

  set value(value: ICustomField) {
    this.$emit('update:modelValue', value)
  }

  customFieldLocal!: ICustomField

  requiredRule = {
    message: 'This field is required',
  }

  COLORS_LIST = COLORS_LIST

  fixModelValue() {
    const value = {
      multiSelect: this.modelValue?.multiSelect ?? false,
      ...cloneDeep(this.modelValue),
    }

    if (!this.value?.options?.length) {
      value.options = [
        {
          color: COLORS_LIST[0],
          value: randomString(),
          label: 'New label',
        },
      ]
    }

    return value
  }

  onAdd() {
    if (!this.value.options) {
      this.value.options = []
    }

    this.value.options.push({
      label: '',
      value: randomString(),
      color: COLORS_LIST[0],
    })

    this.componentKey++
  }

  onRemove(item: ICustomFieldOption) {
    if (!this.value.options) {
      this.value.options = []
    }

    const index = this.value.options?.indexOf(item)
    if (index === undefined) {
      return
    }

    if (index >= 0 && this.value.options) {
      this.value.options.splice(index, 1)
    }

    this.componentKey++
  }

  mounted() {
    this.value = this.fixModelValue()
  }
}
</script>
