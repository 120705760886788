<template>
  <ValidationRuleSetting v-model="value.rules" />
  <q-space class="q-mt-md" />
  <q-list>
    <q-item class="q-pa-none">
      <q-item-section>
        <q-input
          label="Precision"
          type="number"
          dense
          min="0"
          max="10"
          v-model="value.formatStyle.precision"
          hide-bottom-space
        />
      </q-item-section>
    </q-item>
    <q-item class="q-pa-none">
      <q-item-section side>
        <q-input
          dense
          hide-bottom-space
          :label="$t('label.fix_symbol')"
          v-model="value.formatStyle.fixSymbol"
          :disable="!value.formatStyle.fixSymbolPosition"
        />
      </q-item-section>
      <q-item-section>
        <q-select
          dense
          hide-bottom-space
          v-model="value.formatStyle.fixSymbolPosition"
          emit-value
          map-options
          :label="$t('label.fix_symbol_position')"
          :options="fixSymbolPositions"
          :option-label="item => $t(item.label)"
        >
          <template v-slot:append>
            <q-icon
              v-if="value.formatStyle.fixSymbolPosition"
              class="cursor-pointer"
              name="clear"
              @click.stop="onClearFixSymbol"
            />
          </template>
        </q-select>
      </q-item-section>
    </q-item>
    <q-item class="q-pa-none">
      <q-item-section>
        <small>{{ $t('label.preview_value') }}: {{ $filters.formatFieldValue(1000, value.formatStyle) }}</small>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import {
  ECustomFieldFixSymbolType,
  ICustomField,
  ECustomFieldInputType,
} from '@/components/custom-field/custom-field-model'
import ValidationRuleSetting from '@/components/custom-field/validation-rule/ValidationRuleSetting.vue'

@Options({
  components: { ValidationRuleSetting },
})
export default class CustomFieldNumberInputSetting extends Vue {
  @Prop()
  modelValue!: ICustomField

  fixSymbolPositions = [
    {
      label: 'label.prefix',
      value: ECustomFieldFixSymbolType.prefix,
    },
    {
      label: 'label.suffix',
      value: ECustomFieldFixSymbolType.suffix,
    },
  ]

  get value(): ICustomField {
    if (!this.modelValue.formatStyle) {
      this.modelValue.formatStyle = {
        type: ECustomFieldInputType.number,
        precision: 0,
      }
    }

    return this.modelValue
  }

  set value(value: ICustomField) {
    this.$emit('update:modelValue', value)
  }

  onClearFixSymbol() {
    if (this.value.formatStyle) {
      this.value.formatStyle = {
        ...this.value.formatStyle,
        fixSymbol: '',
        fixSymbolPosition: undefined,
      }
    }
  }
}
</script>
