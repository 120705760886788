<template>
  <q-list v-if="value.options.length">
    <q-item class="q-pa-none" v-for="(option, index) in value.options" :key="index">
      <q-item-section>
        <q-input disabled dense disable placeholder="Field label" :model-value="option.label" />
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ICustomField } from '../../custom-field-model'

@Options({
  components: {},
  directives: {},
})
export default class CustomFieldDropdownSettingPreview extends Vue {
  @Prop()
  modelValue!: ICustomField

  get value(): ICustomField {
    return this.modelValue
  }
}
</script>
