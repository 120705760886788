<template>
  <div v-if="value.options.length">
    <q-option-group size="sm" type="radio" :model-value="[]" :options="value.options" disable />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ICustomField } from '../../custom-field-model'

@Options({
  components: {},
  directives: {},
})
export default class CustomFieldRadioSettingPreview extends Vue {
  @Prop()
  modelValue!: ICustomField

  get value(): ICustomField {
    return this.modelValue
  }
}
</script>
