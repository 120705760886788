<template>
  <div v-if="value.options.length">
    <q-chip
      square
      :ripple="false"
      v-for="option in value.options"
      :key="option.value"
      :style="{ background: option.color, color: '#fff' }"
    >
      {{ option.label }}
    </q-chip>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ICustomField } from '../../custom-field-model'

@Options({
  components: {},
})
export default class CustomFieldLabelSettingPreview extends Vue {
  @Prop()
  modelValue!: ICustomField

  get value(): ICustomField {
    return this.modelValue
  }
}
</script>
