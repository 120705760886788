<template>
  <IsRequiredValidationRule v-if="hasRequiredRule" />
  <slot></slot>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Provide } from 'vue-property-decorator'
import { EValidationRuleName, IValidationRule } from '../custom-field-model'

import IsRequiredValidationRule from './IsRequiredValidationRule.vue'

@Options({
  components: {
    IsRequiredValidationRule,
  },
})
export default class ValidationRuleSetting extends Vue {
  @Prop()
  modelValue!: IValidationRule[]

  @Prop({ default: true })
  hasRequiredRule!: boolean

  get rules(): IValidationRule[] {
    return this.modelValue || []
  }

  set rules(rules: IValidationRule[]) {
    this.$emit('update:modelValue', rules)
  }

  @Provide()
  saveRule(rule: IValidationRule) {
    const ruleIndex = this.getRuleIndex(rule.ruleName)
    if (ruleIndex < 0) {
      this.rules = [...[rule]]
    } else {
      this.rules[ruleIndex] = rule
    }
  }

  @Provide()
  removeRule(name: EValidationRuleName) {
    const ruleIndex = this.getRuleIndex(name)
    if (ruleIndex !== -1) {
      this.rules.splice(ruleIndex, 1)
    }
  }

  @Provide()
  getRule(name: EValidationRuleName): IValidationRule | undefined {
    return this.rules.find(p => p.ruleName === name)
  }

  getRuleIndex(ruleName: EValidationRuleName): number {
    return this.rules.findIndex(p => p.ruleName === ruleName)
  }
}
</script>
