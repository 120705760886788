<template>
  <section class="q-pa-md">
    <div class="row no-wrap">
      <h1 class="text-h4">Form detail setting</h1>
    </div>
    <div class="container-smaller q-mt-lg">
      <q-form ref="formRef">
        <label class="text-grey-9 text-bold">Form name</label>
        <q-input
          lazy-rules
          :rules="[val => (val && val.length > 0) || requiredRule.message]"
          outlined
          v-model="form.title"
          dense
          class="text-h5"
          placeholder="form name"
          hide-bottom-space
        />

        <div class="q-mt-md">
          <draggable
            :list="form.fields"
            :forceFallback="true"
            :component-data="{
              group: 'form-fields',
              type: 'transition-group',
              name: 'drag-area',
              class: 'column q-gutter-md',
            }"
            itemKey="position"
            v-bind="{
              animation: 200,
              group: 'task',
              disabled: false,
            }"
            fallbackClass="dragging-item"
            handle=".cursor-move"
            @start="dragging = true"
            @end="dragging = false"
            @update="onUpdateSortOfField"
          >
            <template #item="{ element, index }">
              <div>
                <CustomFieldEditor
                  :key="componentKey"
                  :fieldData="element"
                  :isEditing="editFieldIndex === index"
                  :fieldIndex="index"
                  v-on:cancel="onCancelEdit"
                  v-on:save="onSaveCustomField"
                >
                  <template #header>
                    <q-btn flat padding="0" color="grey" size="sm" class="hide-on-dragging cursor-move">
                      <q-icon name="drag_indicator" />
                    </q-btn>
                    <div class="float-right">
                      <q-btn
                        size="xs"
                        round
                        icon="edit"
                        class="q-mr-sm"
                        @click="onEdit(index)"
                        v-show="editFieldIndex !== index"
                      />
                      <q-btn
                        size="xs"
                        round
                        icon="delete"
                        color="red"
                        @click="onDelete(index)"
                        v-show="editFieldIndex !== index"
                      />
                    </div>
                  </template>
                </CustomFieldEditor>
              </div>
            </template>
          </draggable>
        </div>
        <div class="q-mt-lg">
          <q-btn-dropdown icon="add" padding="0 10px" outline color="primary" label="New field">
            <q-list dense>
              <q-item
                :key="index"
                v-for="(item, index) in CUSTOM_FIELD_TYPE_LIST"
                clickable
                v-close-popup
                @click="onAddCustomField(item)"
              >
                <q-item-section>
                  <q-item-label>{{ item.label }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </q-form>

      <div class="container-smaller q-mt-lg">
        <q-space class="q-my-md" />
        <div class="q-gutter-sm">
          <q-btn color="primary" @click="onSubmit" label="Save form" />
          <q-btn
            no-caps
            flat
            color="grey-6"
            icon="keyboard_backspace"
            @click.prevent="gotoForms"
            label="Back to forms list"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import { QForm } from 'quasar'
import draggable from 'vuedraggable'
import logging from '@/utils/logging'
import { CUSTOM_FIELD_TYPE_LIST } from '@/constants/vars'
import { findIndex } from 'lodash'
import { IForm, FormModel } from '@/components/form/form-model'
import { ICustomField } from '@/components/custom-field/custom-field-model'

import CustomFieldEditor from '@/components/custom-field/CustomFieldEditor.vue'
import FormMixin from '@/components/form/mixins/FormMixin.vue'

@Options({
  components: {
    draggable,
    CustomFieldEditor,
  },
})
export default class FormFieldsEditor extends mixins(FormMixin) {
  componentKey = 0
  editFieldIndex = -1
  CUSTOM_FIELD_TYPE_LIST = CUSTOM_FIELD_TYPE_LIST

  requiredRule = {
    message: 'This field is required',
  }

  form: IForm = {
    title: '',
    description: '',
    fields: [],
  }

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get formId() {
    return this.$route.params.formId as string
  }

  get isNew() {
    return !this.formId
  }

  get forms(): FormModel[] {
    return this.$store.getters.forms(this.workspaceId) || []
  }

  @Watch('formId', { immediate: true })
  async formIdChanged() {
    if (!this.formId) {
      return
    }

    const formDetail = await this.getForm(this.formId)
    this.form = { ...this.form, ...formDetail?.sertialize() }
  }

  onSubmit() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        await this.doSave()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async onDelete(fieldIndex: number) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        if (!this.form.fields) {
          return
        }

        this.form.fields.splice(fieldIndex, 1)
        this.componentKey++
      })
  }

  onEdit(fieldIndex: number) {
    this.editFieldIndex = fieldIndex
  }

  onCancelEdit({ index, field }) {
    if (!this.form.fields) {
      this.form.fields = []
    }

    if (!field.name) {
      this.form.fields.splice(index, 1)
    } else {
      // this.form.fields[index] = field
    }

    this.editFieldIndex = -1
  }

  onSaveCustomField({ index, field }) {
    if (!this.form.fields) {
      this.form.fields = []
    }

    this.form.fields[index] = field
    this.editFieldIndex = -1
  }

  onAddCustomField({ value: fieldType }) {
    this.form.fields = this.form.fields || []
    const newField: ICustomField = {
      label: '',
      type: fieldType,
      defaultValue: '',
      options: [],
    }

    this.form.fields.push(newField)
    this.editFieldIndex = this.form.fields?.length - 1
  }

  onUpdateSortOfField({ oldIndex, newIndex }) {
    this.componentKey++
    if (oldIndex === this.editFieldIndex) {
      this.editFieldIndex = newIndex
    } else {
      this.editFieldIndex = -1
    }
  }

  async doSave() {
    if (this.isNew) {
      await this.addForm(this.form)
      this.gotoForms()
    } else {
      return this.updateForm(this.form)
    }
  }

  getIndexOfField(field: ICustomField) {
    return findIndex(this.form.fields, (item: ICustomField) => item.name === field.name)
  }

  gotoForms() {
    this.$router.push({ name: 'workspace_board.forms' })
  }

  async created() {
    this.$meta.setMeta({ title: ['Form fields setting'] })
    await this.getForms()
  }
}
</script>
