
import { Vue, Options } from 'vue-class-component'
import { Inject } from 'vue-property-decorator'
import { EValidationRuleName } from '../custom-field-model'
import { SaveRuleFunc, GetRuleFunc, RemoveRuleFunc } from './typing'

@Options({})
export default class IsRequiredValidationRule extends Vue {
  @Inject() saveRule!: SaveRuleFunc
  @Inject() getRule!: GetRuleFunc
  @Inject() removeRule!: RemoveRuleFunc

  ruleName = EValidationRuleName.isRequired
  isRequired = false
  errorMessage = ''

  onModelChanged() {
    if (this.isRequired) {
      this.saveRule({
        ruleName: this.ruleName,
        errorMessage: this.errorMessage,
      })
    } else {
      this.removeRule(this.ruleName)
    }
  }

  created() {
    const rule = this.getRule(this.ruleName)
    if (rule) {
      this.isRequired = true
      this.errorMessage = rule.errorMessage || ''
    }
  }
}
