<template>
  <ValidationRuleSetting v-model="value.rules" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ICustomField } from '@/components/custom-field/custom-field-model'
import ValidationRuleSetting from '@/components/custom-field/validation-rule/ValidationRuleSetting.vue'

@Options({
  components: { ValidationRuleSetting },
  directives: { maska },
  emits: [],
})
export default class CustomFieldUploadSetting extends Vue {
  @Prop()
  modelValue!: ICustomField

  get value(): ICustomField {
    return this.modelValue
  }

  set value(value: ICustomField) {
    this.$emit('update:modelValue', value)
  }
}
</script>
