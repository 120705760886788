<template>
  <q-card bordered dense :class="{ 'editing-field': isEditing }">
    <q-card-section class="q-pa-sm bg-grey-3">
      <slot name="header"></slot>
      <q-badge transparent outline align="middle" color="green">
        {{ form.type }}
      </q-badge>
      <div class="float-right" v-if="isEditing">
        <q-btn class="q-mr-sm" size="xs" round icon="reply" @click="onCancelEdit"></q-btn>
        <q-btn class="q-mr-sm" size="xs" round icon="check" color="primary" @click="onSubmit"></q-btn>
      </div>
    </q-card-section>
    <q-separator />

    <!-- Edit settings -->
    <q-card-section class="q-pt-xs" v-if="isEditing">
      <q-form ref="formRef">
        <q-input
          lazy-rules
          dense
          hide-bottom-space
          v-model="form.label"
          label="Label"
          placeholder="Field label"
          :rules="[val => (val && val.length > 0) || requiredRule.message]"
        />
        <q-space class="q-my-md" />
        <q-input v-model="form.placeholder" dense label="Description" />
        <q-space class="q-my-md" />
        <component
          v-model="form"
          :is="customFieldSettingComps[form.type]"
          @update:onChange="onCustomFieldSettingChange"
        />
      </q-form>
    </q-card-section>

    <!-- Preview settings -->
    <q-card-section class="q-pa-sm" v-else>
      <span v-text="form.label" v-field-hint="form.placeholder" v-field-required="ruleParser.isRequired"></span>
      <component v-model="form" :is="customFieldSettingPreviewComps[form.type]" />
    </q-card-section>
    <q-separator />
  </q-card>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { QForm } from 'quasar'
import logging from '@/utils/logging'
import { randomString } from '@/utils/helpers'
import { ECustomFieldType, ICustomField } from './custom-field-model'
import { CUSTOM_FIELD_TYPE_LIST } from '@/constants/vars'
import cloneDeep from 'lodash/cloneDeep'
import { customFieldDirectives } from './fields/directives'
import { ValidationRuleParser } from './validation-rule/ValidationRuleParser'

import CustomFieldInputSetting from './fields/input/CustomFieldInputSetting.vue'
import CustomFieldNumberInputSetting from './fields/number/CustomFieldNumberInputSetting.vue'
import CustomFieldTextareaSetting from './fields/textarea/CustomFieldTextareaSetting.vue'
import CustomFieldDropdownSetting from './fields/dropdown/CustomFieldDropdownSetting.vue'
import CustomFieldUploadSetting from './fields/upload/CustomFieldUploadSetting.vue'
import CustomFieldCheckBoxSetting from './fields/checkbox/CustomFieldCheckBoxSetting.vue'
import CustomFieldDatepickerSetting from './fields/datepicker/CustomFieldDatepickerSetting.vue'
import CustomFieldLabelSetting from './fields/label/CustomFieldLabelSetting.vue'
import CustomFieldRadioSetting from './fields/radio/CustomFieldRadioSetting.vue'

import CustomFieldDropdownSettingPreview from './fields/dropdown/CustomFieldDropdownSettingPreview.vue'
import CustomFieldCheckboxSettingPreview from './fields/checkbox/CustomFieldCheckboxSettingPreview.vue'
import CustomFieldRadioSettingPreview from './fields/radio/CustomFieldRadioSettingPreview.vue'
import CustomFieldLabelSettingPreview from './fields/label/CustomFieldLabelSettingPreview.vue'

@Options({
  components: {},
  directives: {
    ...customFieldDirectives,
  },
})
export default class CustomFieldEditor extends Vue {
  @Prop()
  fieldData!: ICustomField

  @Prop()
  isEditing!: boolean

  @Prop()
  fieldIndex!: number

  customFieldSettingComps = {
    [ECustomFieldType.input]: CustomFieldInputSetting,
    [ECustomFieldType.number]: CustomFieldNumberInputSetting,
    [ECustomFieldType.textarea]: CustomFieldTextareaSetting,
    [ECustomFieldType.dropdown]: CustomFieldDropdownSetting,
    [ECustomFieldType.upload]: CustomFieldUploadSetting,
    [ECustomFieldType.checkbox]: CustomFieldCheckBoxSetting,
    [ECustomFieldType.datepicker]: CustomFieldDatepickerSetting,
    [ECustomFieldType.label]: CustomFieldLabelSetting,
    [ECustomFieldType.radio]: CustomFieldRadioSetting,
  }

  customFieldSettingPreviewComps = {
    [ECustomFieldType.dropdown]: CustomFieldDropdownSettingPreview,
    [ECustomFieldType.checkbox]: CustomFieldCheckboxSettingPreview,
    [ECustomFieldType.radio]: CustomFieldRadioSettingPreview,
    [ECustomFieldType.label]: CustomFieldLabelSettingPreview,
  }

  requiredRule = {
    message: 'This field is required',
  }

  form: ICustomField = {
    label: '',
    type: CUSTOM_FIELD_TYPE_LIST[0].value,
    defaultValue: '',
    options: [],
  }

  get isNew() {
    return !this.fieldData?.name
  }

  get ruleParser() {
    return ValidationRuleParser(this.form.type, this.form.rules)
  }

  onCustomFieldSettingChange(customField: ICustomField) {
    this.form = { ...this.form, ...customField }
  }

  onSubmit() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }

        if (this.isNew) {
          this.form.name = randomString()
        }

        this.$emit('save', {
          index: this.fieldIndex,
          field: this.form,
        })
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  onCancelEdit() {
    this.form = Object.assign({}, this.fieldData)
    this.$emit('cancel', {
      index: this.fieldIndex,
      field: this.fieldData,
    })
  }

  async created() {
    this.form = cloneDeep(this.fieldData)
  }
}
</script>

<style lang="scss" scope>
.editing-field {
  border-top: solid 5px #66bb6a;
}
</style>
