
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { ICustomField } from '../../custom-field-model'

@Options({
  components: {},
  directives: {},
})
export default class CustomFieldCheckboxSettingPreview extends Vue {
  @Prop()
  modelValue!: ICustomField

  get value(): ICustomField {
    return this.modelValue
  }
}
