
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import {
  ECustomFieldFixSymbolType,
  ICustomField,
  ECustomFieldInputType,
} from '@/components/custom-field/custom-field-model'
import ValidationRuleSetting from '@/components/custom-field/validation-rule/ValidationRuleSetting.vue'

@Options({
  components: { ValidationRuleSetting },
})
export default class CustomFieldNumberInputSetting extends Vue {
  @Prop()
  modelValue!: ICustomField

  fixSymbolPositions = [
    {
      label: 'label.prefix',
      value: ECustomFieldFixSymbolType.prefix,
    },
    {
      label: 'label.suffix',
      value: ECustomFieldFixSymbolType.suffix,
    },
  ]

  get value(): ICustomField {
    if (!this.modelValue.formatStyle) {
      this.modelValue.formatStyle = {
        type: ECustomFieldInputType.number,
        precision: 0,
      }
    }

    return this.modelValue
  }

  set value(value: ICustomField) {
    this.$emit('update:modelValue', value)
  }

  onClearFixSymbol() {
    if (this.value.formatStyle) {
      this.value.formatStyle = {
        ...this.value.formatStyle,
        fixSymbol: '',
        fixSymbolPosition: undefined,
      }
    }
  }
}
