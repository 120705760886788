
import { Options, mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

import QForm from 'quasar/src/components/form/QForm.js';
import draggable from 'vuedraggable'
import logging from '@/utils/logging'
import { CUSTOM_FIELD_TYPE_LIST } from '@/constants/vars'
import { findIndex } from 'lodash'
import { IForm, FormModel } from '@/components/form/form-model'
import { ICustomField } from '@/components/custom-field/custom-field-model'

import CustomFieldEditor from '@/components/custom-field/CustomFieldEditor.vue'
import FormMixin from '@/components/form/mixins/FormMixin.vue'

@Options({
  components: {
    draggable,
    CustomFieldEditor,
  },
})
export default class FormFieldsEditor extends mixins(FormMixin) {
  componentKey = 0
  editFieldIndex = -1
  CUSTOM_FIELD_TYPE_LIST = CUSTOM_FIELD_TYPE_LIST

  requiredRule = {
    message: 'This field is required',
  }

  form: IForm = {
    title: '',
    description: '',
    fields: [],
  }

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get formId() {
    return this.$route.params.formId as string
  }

  get isNew() {
    return !this.formId
  }

  get forms(): FormModel[] {
    return this.$store.getters.forms(this.workspaceId) || []
  }

  @Watch('formId', { immediate: true })
  async formIdChanged() {
    if (!this.formId) {
      return
    }

    const formDetail = await this.getForm(this.formId)
    this.form = { ...this.form, ...formDetail?.sertialize() }
  }

  onSubmit() {
    const form = this.$refs.formRef as QForm
    form
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        await this.doSave()
      })
      .catch((error: unknown) => {
        logging.debug(error)
      })
  }

  async onDelete(fieldIndex: number) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        if (!this.form.fields) {
          return
        }

        this.form.fields.splice(fieldIndex, 1)
        this.componentKey++
      })
  }

  onEdit(fieldIndex: number) {
    this.editFieldIndex = fieldIndex
  }

  onCancelEdit({ index, field }) {
    if (!this.form.fields) {
      this.form.fields = []
    }

    if (!field.name) {
      this.form.fields.splice(index, 1)
    } else {
      // this.form.fields[index] = field
    }

    this.editFieldIndex = -1
  }

  onSaveCustomField({ index, field }) {
    if (!this.form.fields) {
      this.form.fields = []
    }

    this.form.fields[index] = field
    this.editFieldIndex = -1
  }

  onAddCustomField({ value: fieldType }) {
    this.form.fields = this.form.fields || []
    const newField: ICustomField = {
      label: '',
      type: fieldType,
      defaultValue: '',
      options: [],
    }

    this.form.fields.push(newField)
    this.editFieldIndex = this.form.fields?.length - 1
  }

  onUpdateSortOfField({ oldIndex, newIndex }) {
    this.componentKey++
    if (oldIndex === this.editFieldIndex) {
      this.editFieldIndex = newIndex
    } else {
      this.editFieldIndex = -1
    }
  }

  async doSave() {
    if (this.isNew) {
      await this.addForm(this.form)
      this.gotoForms()
    } else {
      return this.updateForm(this.form)
    }
  }

  getIndexOfField(field: ICustomField) {
    return findIndex(this.form.fields, (item: ICustomField) => item.name === field.name)
  }

  gotoForms() {
    this.$router.push({ name: 'workspace_board.forms' })
  }

  async created() {
    this.$meta.setMeta({ title: ['Form fields setting'] })
    await this.getForms()
  }
}
