
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { maska } from 'maska'
import { randomString } from '@/utils/helpers'
import { COLORS_LIST } from '@/constants/vars'
import { ICustomField, ICustomFieldOption } from '@/components/custom-field/custom-field-model'
import ValidationRuleSetting from '@/components/custom-field/validation-rule/ValidationRuleSetting.vue'

@Options({
  components: { ValidationRuleSetting },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class CustomFieldDropdownSetting extends Vue {
  @Prop()
  modelValue!: ICustomField

  get value(): ICustomField {
    return this.modelValue
  }

  set value(value: ICustomField) {
    this.$emit('update:modelValue', value)
  }

  customFieldLocal!: ICustomField

  requiredRule = {
    message: 'This field is required',
  }

  COLORS_LIST = COLORS_LIST

  fixModelValue() {
    const value = {
      ...cloneDeep(this.modelValue),
    }

    if (!this.value?.options?.length) {
      value.options = [
        {
          color: COLORS_LIST[0],
          value: randomString(),
          label: 'New option',
        },
      ]
    }

    return value
  }

  onAdd() {
    if (!this.value.options) {
      this.value.options = []
    }

    this.value.options.push({
      label: '',
      value: randomString(),
      color: COLORS_LIST[0],
    })
  }

  onRemove(item: ICustomFieldOption) {
    if (!this.value.options) {
      this.value.options = []
    }

    const index = this.value.options?.indexOf(item)
    if (index === undefined) {
      return
    }

    if (index >= 0 && this.value.options) {
      this.value.options.splice(index, 1)
    }
  }

  created() {
    this.value = this.fixModelValue()
  }
}
