import { render } from "./CustomFieldLabelSetting.vue?vue&type=template&id=d6528242"
import script from "./CustomFieldLabelSetting.vue?vue&type=script&lang=ts"
export * from "./CustomFieldLabelSetting.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToggle,QSpace,QList,QItem,QItemSection,QInput,QField,QBtn});
