import { render } from "./FormFieldsEditor.vue?vue&type=template&id=59308a7e"
import script from "./FormFieldsEditor.vue?vue&type=script&lang=ts"
export * from "./FormFieldsEditor.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpace from 'quasar/src/components/space/QSpace.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QForm,QInput,QBtn,QIcon,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QSpace});qInstall(script, 'directives', {ClosePopup});
